<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        lazy
        transition="scale-transition"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="date"
          label="Select date"
          prepend-inner-icon="mdi-calendar-today"
          readonly
        />
        <v-date-picker
          v-model="date"
          :max="today"
          min="2020-04-01"
          type="date"
          no-title
        />
      </v-menu>
      &nbsp;
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
      <v-spacer />
    </v-toolbar>
    <v-card
      v-if="kpi2022"
      color="black"
    >
      <v-card-text
        style="height: 10px;
        position: relative"
      >
        <v-btn
          absolute
          dark
          fab
          top
          right
          :ripple="false"
          large
          :color="kpiAchievement ? `black` : `white`"
        >
          <v-avatar
            size="50px"
            :color="kpiAchievement ? kpiColor : `white`"
          >
            <span
              v-if="(typeof kpiAchievement === 'number' && kpiAchievement > 0) || kpiAchievement !== '0'"
              class="white--text subheader"
            >
              KPI 0{{ kpiAchievement }}
            </span>
            <span
              v-else
              class="black--text subheader"
            >
              KPI VOID
            </span>
          </v-avatar>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :rows-per-page-items="[50, 100, 200]"
      :search="search"
      item-key="entry"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.source }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
          <template v-if="kpi2022">
            <td
              v-if="kpiAchievement === '1' || kpiAchievement === '1A' || kpiAchievement === '1B' || kpiAchievement === '1C'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi01 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi01 }}
            </td>
            <td
              v-if="kpiAchievement === '2'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi02 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi02 }}
            </td>
            <td
              v-if="kpiAchievement === '3'"
              class="text-xs-right info--text"
            >
              {{ props.item.kpi03 }}
            </td>
            <td
              v-else
              class="text-xs-right"
            >
              {{ props.item.kpi03 }}
            </td>
          </template>
          <template v-else>
            <td class="text-xs-right">
              {{ props.item.incentive }}
            </td>
          </template>
          <td class="text-xs-center">
            {{ props.item.tier }}
          </td>
          <td
            v-if="props.item.percent >= '0.1%' && props.item.percent <= '1.0%' "
            class="text-xs-center"
          >
            {{ roundof1 }}
          </td>
          <td
            v-else-if="props.item.percent === '9%'"
            class="text-xs-center"
          >
            {{ roundof10 }}
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            {{ props.item.percent }}
          </td>
          <td class="text-xs-center">
            {{ props.item.remark ? props.item.remark : '-' }}
          </td>
        </tr>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalReload }}
          </td>
          <template v-if="kpi2022">
            <td class="text-xs-right info">
              RM {{ totalKpi01 }}
            </td>
            <td class="text-xs-right info">
              RM {{ totalKpi02 }}
            </td>
            <td class="text-xs-right info">
              RM {{ totalKpi03 }}
            </td>
          </template>
          <template v-else>
            <td class="text-xs-right info">
              RM {{ totalIncentive }}
            </td>
          </template>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import security from '@/security'

const title = 'Daily Details'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: 'RI', disabled: false, to: '/royalty_incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      roundof1: '1%',
      roundof10: '10%',
      date: null,
      items: [],
      kpiAchievement: 0,
      loading: false,
      maxDate: '',
      menu: false,
      riHeader: null,
      search: '',
      title: title,
      totalIncentive: 0.00,
      totalKpi01: 0.00,
      totalKpi02: 0.00,
      totalKpi03: 0.00,
      totalReload: 0.00,
    }
  },
  computed: {
    kpiColor: function () {
      if (typeof this.kpiAchievement === 'number') {
        switch (this.kpiAchievement * 1) {
          case 3: return '#800000'
          case 2: return 'orange darken-3'
          case 1: return 'grey'
          default: return 'white'
        }
      } else {
        if (this.kpiAchievement === '1A') {
          return 'grey'
        } else if (this.kpiAchievement === '1B') {
          return 'grey darken-2'
        } else if (this.kpiAchievement === '1C') {
          return 'grey darken-3'
        } else if (this.kpiAchievement === '2') {
          return 'orange darken-3'
        } else if (this.kpiAchievement === '3') {
          return '#800000'
        } else {
          return 'white'
        }
      }
    },
    kpi2022: function () {
      if (!this.date) return false

      const ym = this.date.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2022, 3, 1).setZone('Asia/Kuala_Lumpur')
      const toDate = DateTime.local(2024, 3, 31).setZone('Asia/Kuala_Lumpur')
      // const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const applicableDealer = security.me.dealerRank() === 'Dealer' || security.me.dealerRank() === 'Sub Dealer'

      return Interval.fromDateTimes(fromDate, toDate).contains(whatMonth) && applicableDealer
    },
    kpiSept2022: function () {
      if (!this.date) return false

      const ym = this.date.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2022, 9, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      const applicableDealer = security.me.dealerRank() === 'Dealer' || security.me.dealerRank() === 'Sub Dealer'

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth) && applicableDealer
    },
    today: function () {
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      return now.toFormat('yyyy-LL-dd')
    },
    headers: function () {
      const headers = [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Source',
          align: 'left',
          sortable: true,
          value: 'source',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: true,
          value: 'reload',
        },
      ]

      if (this.kpi2022) {
        headers.push(
          {
            text: this.kpiSept2022 ? (this.kpiAchievement === '1C' ? 'KPI 01C' : (this.kpiAchievement === '1B' ? 'KPI 01B' : 'KPI 01A')) : 'KPI 01',
            align: 'right',
            sortable: false,
            value: 'kpi01',
          },
          {
            text: 'KPI 02',
            align: 'right',
            sortable: false,
            value: 'kpi02',
          },
          {
            text: 'KPI 03',
            align: 'right',
            sortable: false,
            value: 'kpi03',
          },
        )
      } else {
        headers.push(
          {
            text: 'Royalty Incentive (RM)',
            align: 'right',
            sortable: false,
            value: 'incentive',
          },
        )
      }

      headers.push(
        {
          text: 'Tier',
          align: 'center',
          sortable: true,
          value: 'tier',
        },
        {
          text: 'Percent',
          align: 'center',
          sortable: true,
          value: 'percent',
        },
        {
          text: 'Remark',
          align: 'Left',
          sortable: true,
          value: 'remark',
        },
      )

      return headers
    },
  },
  watch: {
    date: function (date) {
      date && this.getDetailReloads(date)
    },
  },
  methods: {
    getDetailReloads: function (date) {
      this.$refs.menu.save(date)
      this.getRoyaltyIncentive(date)
      this.menu = false
    },
    getRoyaltyIncentive: function (date) {
      const param = { time: date }
      this.loading = true
      this.alert = false
      this.items = []
      this.totalIncentive = this.totalReload = '0.00'
      this.totalKpi01 = this.totalKpi02 = this.totalKpi03 = '0.00'
      this.$rest.get('getRoyaltyIncentive.php', createGetParams(param))
        .then(function (response) {
          this.items = response.data.item
          this.totalReload = response.data.totalReload
          this.totalIncentive = response.data.totalIncentive
          this.totalKpi01 = response.data.totalKpi01
          this.totalKpi02 = response.data.totalKpi02
          this.totalKpi03 = response.data.totalKpi03
          this.kpiAchievement = response.data.kpiAchievement
          if (response.data.message) {
            this.alertMessage = response.data.message
            this.alertType = 'info'
            this.alert = true
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alertType = 'error'
          this.alert = true
          this.loading = false
        })
    },
  },
}
</script>
